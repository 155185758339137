export const MODULES = [
    {
        driver_id: 'dep-WjCyo9xO8H',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.250',
        tls: false,
        udp: false,
        port: 12004,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {},
        updated_at: 1587079791,
        created_at: 1551149982,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-m2dku__78J',
        edge: { name: 'Master Node', description: null },
        name: 'KNX BAOS Lighting',
        description: null,
        module_name: 'Lighting',
    },
    {
        driver_id: 'dep-WjCyo9xO8H',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.250',
        tls: false,
        udp: false,
        port: 12004,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {},
        updated_at: 1587080422,
        created_at: 1551149966,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-m2dikgBoVz',
        name: 'KNX BAOS Lighting',
        description: null,
        module_name: 'Lighting',
    },
    {
        driver_id: 'dep-WjCyo9xO8H',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.169',
        tls: false,
        udp: false,
        port: 12004,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {},
        updated_at: 1587079371,
        created_at: 1551144140,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-m2RqG32afs',
        name: 'KNX BAOS Lighting',
        description: null,
        module_name: 'Lighting',
    },
    {
        driver_id: 'dep-WjCyo9xO8H',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.169',
        tls: false,
        udp: false,
        port: 12004,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {},
        updated_at: 1587441693,
        created_at: 1551144074,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-m2RhX0-JNm',
        name: 'KNX BAOS Lighting',
        description: null,
        module_name: 'Lighting',
    },
    {
        driver_id: 'dep-WjCyo9xO8H',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.169',
        tls: false,
        udp: false,
        port: 12004,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {},
        updated_at: 1587436473,
        created_at: 1551143972,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-m2RT_5-NeO',
        name: 'KNX BAOS Lighting',
        description: null,
        module_name: 'Lighting',
    },
    {
        driver_id: 'dep-WjCyo9xO8H',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.169',
        tls: false,
        udp: false,
        port: 12004,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {},
        updated_at: 1587080422,
        created_at: 1551143894,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-m2RJg7Z-YD',
        name: 'KNX BAOS Lighting',
        description: null,
        module_name: 'Lighting',
    },
    {
        driver_id: 'dep-KvMtm2DJm8',
        control_system_id: 'sys-l7lvddob4I',
        edge_id: 'edge-KiBexw2DWK',
        ip: null,
        tls: null,
        udp: null,
        port: null,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {
            joined: { initiator: 'sys-l7lvddob4I', rooms: ['sys-l7lvddob4I'] },
        },
        updated_at: 1587515684,
        created_at: 1549322820,
        role: 3,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-lAHXR38sRX',
        name: 'ACA Meeting Room Logic',
        description: null,
        module_name: 'System',
    },
    {
        driver_id: 'dep-RygISIbluK',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: 'n/a',
        tls: false,
        udp: false,
        port: 4999,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {},
        updated_at: 1549344031,
        created_at: 1549242155,
        role: 1,
        connected: null,
        running: false,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-l7l-m0gK7G',
        name: 'Samsung MD & DM Series LCD',
        description:
            'For DM displays configure the following options:\n\n1. Network Standby = OFF (reduces the chance of a display crashing)\n2. Set Auto Standby = OFF\n3. Set Eco Solution, Auto Off = OFF\n\nHard Power off displays each night and wake on lan them in the morning.\n\n\ndefault port changed from 1515 to 4999 as they are all on globalcache itachs',
        module_name: 'Display',
    },
    {
        driver_id: 'dep-hxXtCor9su',
        control_system_id: 'sys-beJ1G79tKo',
        edge_id: 'edge-KiBexw2DWK',
        ip: null,
        tls: null,
        udp: null,
        port: null,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {
            ids: {
                tilt_down: '3122-RGHT-PTZ-12x72TiltDown',
                tilt_up: '3122-RGHT-PTZ-12x72TiltUp',
                pan_left: '3122-RGHT-PTZ-12x72PanLeft',
                pan_right: '3122-RGHT-PTZ-12x72PanRight',
                zoom_in: '3122-RGHT-PTZ-12x72ZoomIn',
                zoom_out: '3122-RGHT-PTZ-12x72ZoomOut',
                preset_home_load: '3122-RGHT-PTZ-12x72Home',
                power: '3122-RGHT-PTZ-12x72PrivacyMode',
            },
        },
        updated_at: 1587515684,
        created_at: 1548297502,
        role: 3,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-kh5_cqZdkx',
        name: 'QSC PTZ Camera Proxy',
        description: null,
        module_name: 'Camera',
    },
    {
        driver_id: 'dep-hxXtCor9su',
        control_system_id: 'sys-beGO1NjTjy',
        edge_id: 'edge-KiBexw2DWK',
        ip: null,
        tls: null,
        udp: null,
        port: null,
        makebreak: false,
        uri: null,
        custom_name: null,
        settings: {
            ids: {
                tilt_down: '3122-LEFT-PTZ-12x72TiltDown',
                tilt_up: '3122-LEFT-PTZ-12x72TiltUp',
                pan_left: '3122-LEFT-PTZ-12x72PanLeft',
                pan_right: '3122-LEFT-PTZ-12x72PanRight',
                zoom_in: '3122-LEFT-PTZ-12x72ZoomIn',
                zoom_out: '3122-LEFT-PTZ-12x72ZoomOut',
                preset_home_load: '3122-LEFT-PTZ-12x72Home',
                power: '3122-LEFT-PTZ-12x72PrivacyMode',
            },
        },
        updated_at: 1587515684,
        created_at: 1548297284,
        role: 3,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-kh5Ym9MnQB',
        name: 'QSC PTZ Camera Proxy',
        description: null,
        module_name: 'Camera',
    },
    {
        driver_id: 'dep-S9OfntF_TR',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.83',
        tls: false,
        udp: false,
        port: 4998,
        makebreak: false,
        uri: null,
        custom_name: 'Door',
        settings: {},
        updated_at: 1580144611,
        created_at: 1548202944,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-ke85vi5csE',
        name: 'GlobalCache IO Gateway',
        description: null,
        module_name: 'DigitalIO',
    },
    {
        driver_id: 'dep-S9OfntF_TR',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.82',
        tls: false,
        udp: false,
        port: 4998,
        makebreak: false,
        uri: null,
        custom_name: 'Door',
        settings: {},
        updated_at: 1580144613,
        created_at: 1548202920,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-ke82maU_gO',
        name: 'GlobalCache IO Gateway',
        description: null,
        module_name: 'DigitalIO',
    },
    {
        driver_id: 'dep-S9OfntF_TR',
        control_system_id: null,
        edge_id: 'edge-KiBexw2DWK',
        ip: '10.224.6.84',
        tls: false,
        udp: false,
        port: 4998,
        makebreak: false,
        uri: null,
        custom_name: 'Door',
        settings: {},
        updated_at: 1580144611,
        created_at: 1548202823,
        role: 1,
        connected: true,
        running: true,
        notes: null,
        ignore_connected: false,
        ignore_startstop: false,
        id: 'mod-ke7sqoaUYx',
        name: 'GlobalCache IO Gateway',
        description: null,
        module_name: 'DigitalIO',
    },
];
