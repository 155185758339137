<header>
    <h3 mat-dialog-title>
        Module State - {{module?.custom_name || module?.name}}</h3>
    <button mat-icon-button mat-dialog-close>
        <app-icon [icon]="{ type: 'icon', class: 'backoffice-cross' }"></app-icon>
    </button>
</header>
<mat-dialog-content>
    <div class="body" *ngIf="!loading; else load_state">
        <div class="field">
            <label>System:</label>
            <div class="value">{{system?.name}}</div>
        </div>
        <div class="field">
            <label>Module:</label>
            <div class="value">
                {{ device_classes[module?.id] }}
            </div>
        </div>
        <button mat-button (click)="updateState()">Update state</button>
        <div class="settings">
            <settings-form-field [ngModel]="state" [readonly]="true"></settings-form-field>
        </div>
    </div>
</mat-dialog-content>
<ng-template #load_state>
    <div class="body">
        <div class="info-block">
            <div class="icon">
                <mat-spinner diameter="32"></mat-spinner>
            </div>
            <div class="text">Loading state...</div>
        </div>
    </div>
</ng-template>
