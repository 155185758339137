<header>
    <div class="heading">Update metadata details</div>
    <button mat-icon-button mat-dialog-close>
        <app-icon [icon]="{ type: 'icon', class: 'backoffice-cross' }"></app-icon>
    </button>
</header>
<mat-dialog-content>
    <main *ngIf="form" [formGroup]="form">
        <div class="field" *ngIf="form.controls.name">
            <label
                for="property-name"
                [class.error]="form.controls.name.invalid && form.controls.name.touched"
                >Name<span>*</span>:</label
            >
            <mat-form-field appearance="outline">
                <input
                    matInput
                    name="property-name"
                    placeholder="Property Name"
                    formControlName="name"
                    required
                />
                <mat-error *ngIf="form.controls.name.invalid">{{
                    form.controls.name.errors.name
                        ? 'Property name must be unique'
                        : 'Property name is required'
                }}</mat-error>
            </mat-form-field>
        </div>
        <div class="field" *ngIf="form.controls.description">
            <label for="description">Description:</label>
            <mat-form-field appearance="outline">
                <textarea
                    matInput
                    name="description"
                    placeholder="Description"
                    formControlName="description"
                ></textarea>
            </mat-form-field>
        </div>
        <div class="field" *ngIf="form.controls.editors">
            <label for="system-email" i18n="@@editorssLabel">Editors:</label>
            <mat-form-field appearance="outline">
                <mat-chip-list #chipList aria-label="Editor Groups">
                    <mat-chip
                        *ngFor="let editor of editors"
                        [selectable]="true"
                        [removable]="true"
                        (removed)="removeEditor(editor)"
                    >
                        {{ editor }}
                        <app-icon matChipRemove [icon]="{ class: 'backoffice-cross' }"></app-icon>
                    </mat-chip>
                    <input
                        placeholder="Editors user groups..."
                        i18n-placeholder="@@editorsPlaceholder"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separators"
                        [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="addEditor($event)"
                    />
                </mat-chip-list>
            </mat-form-field>
        </div>
        <div class="field" *ngIf="form.controls.schema">
            <label for="schema">Schema:</label>
            <mat-form-field appearance="outline">
                <input matInput name="schema" placeholder="Schema" formControlName="schema" />
            </mat-form-field>
        </div>
    </main>
</mat-dialog-content>
<mat-dialog-actions>
    <footer>
        <button [disabled]="!form?.controls.name.valid" mat-button (click)="updateDetails()">
            Update
        </button>
    </footer>
</mat-dialog-actions>
