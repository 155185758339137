export const ZONES = [
    {
        name: 'Place Technology',
        description: 'org',
        tags: 'org',
        settings: {
            touch_enabled: false,
            discovery_info: {
                settings: {
                    title: 'Place Technology',
                    name: 'Place Technology',
                    description:
                        'Place Technology Staff Application made in Angular 5+',
                    short_name: 'Towers',
                    code: 'TOWERS',
                    login: { forgot: false },
                    analytics: { enabled: false, tracking_id: '' },
                    logo: {
                        type: 'icon',
                        src: 'icon-Logo-Long',
                        background: '',
                    },
                    copyright: 'Copyright 2018 PlaceOS',
                    routing: false,
                    orgs: true,
                    style: { popout: false },
                    users: {
                        visitors: false,
                        external: true,
                        can_add: true,
                        custom_orgs: false,
                        locate: true,
                        store: false,
                    },
                    schedule: {
                        hide_visitor_bookings: true,
                        enabled: true,
                        visitors: true,
                        new_visitors: false,
                        edit_visitors: true,
                        contacts: false,
                        popout: false,
                    },
                    route_settings: { book: 'booking' },
                    lockers: { enabled: true, simple_filters: false },
                    hide: { heading: false },
                    home: { background: '', disclaimer: '' },
                    wifi: { background: 'assets/img/skyline.jpg' },
                    tiles: [
                        {
                            id: 'book/room',
                            name: 'Spaces',
                            color: '#fff',
                            img: 'assets/img/menu/book.svg',
                            settings: 'booking',
                            query: { back: false },
                        },
                        {
                            id: 'schedule',
                            name: 'My Activities',
                            color: '#fff',
                            img: 'assets/img/menu/schedule.svg',
                        },
                        {
                            id: 'catering',
                            name: 'Services',
                            color: '#fff',
                            img: 'assets/img/menu/services.svg',
                            settings: 'catering',
                        },
                        {
                            id: 'chat',
                            name: 'Concierge',
                            color: '#fff',
                            img: 'assets/img/menu/concierge.svg',
                        },
                        {
                            id: 'visitor',
                            name: 'Visitors',
                            color: '#fff',
                            icon: { class: 'material-icons', value: 'face' },
                            settings: 'visitors',
                        },
                        {
                            id: 'explore/spaces',
                            name: 'Way finding',
                            color: '#fff',
                            img: 'assets/img/menu/explore.svg',
                            settings: 'explore',
                        },
                        {
                            id: 'control',
                            name: 'Control',
                            color: '#fff',
                            icon: {
                                class: 'material-icons',
                                value: 'settings_remote',
                            },
                        },
                    ],
                    zones: { enabled: false, toggle: 'room' },
                    booking: {
                        min_attendees: 1,
                        external_visitors: false,
                        recurrence: false,
                        limit: 12,
                        max_length: 480,
                        min_length: 15,
                        room_select: false,
                        title_prefix: '',
                        charge: true,
                        terms: true,
                        old_filters: false,
                        privacy: false,
                        control: false,
                        lock_cancel: 120,
                        external_floors: [],
                        return_id: 'schedule',
                        banner: { enabled: true, links: [] },
                    },
                    visitors: {
                        banner: {
                            enabled: false,
                            src: 'assets/img/banner2.jpg',
                            links: [
                                {
                                    id: 'book/room',
                                    name: 'Book Spaces',
                                    query: { back: false },
                                },
                                { id: 'catering', name: 'Book Catering' },
                                { id: 'lockers', name: 'Book Lockers' },
                            ],
                        },
                    },
                    explore: {
                        old_overlay: false,
                        cblf: false,
                        control: false,
                        banner: {
                            search: true,
                            roles: true,
                            src: 'assets/img/banner.jpg',
                        },
                    },
                    control: {
                        banner: { search: false, src: 'assets/img/banner.jpg' },
                    },
                    people_min_char: 0,
                    catering: { enabled: true, banner: { links: [] } },
                    colors: {
                        rooms: {
                            available: '#9cbe82',
                            unavailable: '#be8c64',
                            'not-bookable': '#e6e6e6',
                            pin: '#e96e20',
                        },
                        desks: {
                            'available-fill': '#8cd4da',
                            'available-stroke': '#6ab2af',
                            'unavailable-fill': '#ec9d99',
                            'unavailable-stroke': '#ca7b77',
                            'reserved-fill': '#FF9800',
                            'reserved-stroke': '#E65100',
                            'not-bookable-fill': '#e6e6e6',
                            'not-bookable-stroke': '#c4c4c4',
                        },
                    },
                    help: {
                        type: '',
                        banner: { enabled: false },
                        disclaimer: '',
                        tiles: [
                            {
                                name: 'AV Support',
                                description: '',
                                src: '',
                                color: '',
                                link: 'https://www.google.com',
                            },
                            {
                                name: 'IT Help',
                                description: '',
                                src: '',
                                color: '',
                                link: 'https://www.acaprojects.com',
                            },
                            {
                                name: 'Emergency Contact',
                                description: '',
                                src: '',
                                color: '',
                                link: 'https://www.ses.nsw.gov.au',
                            },
                            {
                                name: 'Website',
                                description: '',
                                src: '',
                                color: '',
                                link: 'https://www.acaprojects.com',
                            },
                        ],
                    },
                    map: {
                        simple: true,
                        info: false,
                        settings: false,
                        bookings: false,
                        keys: {},
                    },
                },
                buildings: [
                    {
                        name: 'Barangaroo Tower 2',
                        zone_id: 'zone-Kl0HN~nDwc',
                        orientations: { landscape: 0 },
                    },
                ],
            },
        },
        triggers: [],
        created_at: 1494571161,
        id: 'zone-Kl0E0HmCJ3',
    },
    {
        name: 'L30 Activity Spaces',
        description: null,
        tags: null,
        settings: {
            mics: [{ name: 'Soundbar Mic', id: 'mic', basic_mixer: true }],
            inputs: ['Wireless', 'Laptop', 'Web Conference', 'Tele Conference'],
            Laptop: ['laptop_hdmi'],
            Wireless: ['wepresent'],
            'Web Conference': ['webconf'],
            'Tele Conference': ['teleconference'],
            sources: {
                wepresent: {
                    title: 'Clickshare',
                    source: 'hdmi2',
                    type: 'wireless',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['aux'] },
                    ],
                },
                laptop_hdmi: {
                    title: 'Laptop',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['aux'] },
                    ],
                },
                webconf: {
                    title: 'Web Conference',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['aux'] },
                    ],
                },
                teleconference: {
                    title: 'Tele Conference',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        {
                            module: 'Mixer_1',
                            method: 'source',
                            args: ['bluetooth'],
                        },
                    ],
                },
            },
            touch_enabled: false,
            outputs: {
                Display_1: {
                    title: 'Display',
                    type: 'lcd',
                    pri: 1,
                    basic_mixer: true,
                    mixer_id: 'output',
                    custom_tasks: [
                        {
                            module: 'Display_1',
                            method: 'volume',
                            args: ['100'],
                        },
                        {
                            module: 'Display_1',
                            method: 'speaker_select',
                            args: ['external'],
                        },
                    ],
                    shutdown: {
                        module: 'Display_1',
                        method: 'speaker_select',
                        args: ['internal'],
                    },
                },
            },
            vol_min: 0,
            vol_max: 100,
            defaults: { output_level: 80 },
            lighting_group: 1,
        },
        triggers: [],
        created_at: 1543374809,
        id: 'zone-iIdF20naW0',
    },
    {
        name: 'L31 Activity Spaces',
        description: '',
        tags: '',
        settings: {
            mics: [{ name: 'Soundbar Mic', id: 'mic', basic_mixer: true }],
            inputs: ['Wireless', 'Laptop', 'Web Conference', 'Tele Conference'],
            Laptop: ['laptop_hdmi'],
            Wireless: ['wepresent'],
            'Web Conference': ['webconf'],
            'Tele Conference': ['teleconference'],
            sources: {
                wepresent: {
                    title: 'Clickshare',
                    source: 'dvi',
                    type: 'wireless',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['aux'] },
                    ],
                },
                laptop_hdmi: {
                    title: 'Laptop',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['aux'] },
                    ],
                },
                webconf: {
                    title: 'Web Conference',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['usb'] },
                    ],
                },
                teleconference: {
                    title: 'Tele Conference',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        {
                            module: 'Mixer_1',
                            method: 'source',
                            args: ['bluetooth'],
                        },
                    ],
                },
            },
            touch_enabled: false,
            outputs: {
                Display_1: {
                    title: 'Display',
                    type: 'lcd',
                    pri: 1,
                    basic_mixer: true,
                    mixer_id: 'output',
                    custom_tasks: [
                        {
                            module: 'Display_1',
                            method: 'volume',
                            args: ['100'],
                        },
                        {
                            module: 'Display_1',
                            method: 'speaker_select',
                            args: ['external'],
                        },
                    ],
                    shutdown: {
                        module: 'Display_1',
                        method: 'speaker_select',
                        args: ['internal'],
                    },
                },
            },
            vol_min: 0,
            vol_max: 100,
            lighting_group: 1,
        },
        triggers: ['trigger-WzXonXrB4G'],
        created_at: 1519368108,
        id: 'zone-WjDE_sLQy8',
    },
    {
        name: 'L31 Multifunction: 31.22',
        description: null,
        tags: null,
        settings: {
            rooms: ['sys-beGO1NjTjy', 'sys-beJ1G79tKo'],
            ignore_modes: true,
            ignore_joining: true,
            inputs: ['Wireless', 'Laptop', 'Web Conference', 'Camera'],
            Laptop: ['floorbox_left'],
            Wireless: ['wepresent_left'],
            'Web Conference': ['webconf'],
            Camera: ['camera'],
            sources: {
                wepresent_left: {
                    title: 'Clickshare',
                    input: 1,
                    type: 'wireless',
                },
                wepresent_right: {
                    title: 'Clickshare',
                    input: 2,
                    type: 'wireless',
                },
                floorbox_left: {
                    title: 'Left Table Input',
                    type: 'aux_hdmi',
                    input: 7,
                },
                floorbox_right: {
                    title: 'Right Table Input',
                    type: 'aux_hdmi',
                    input: 8,
                },
                webconf: {
                    title: 'Web Conference',
                    source: 'hdmi',
                    input: 7,
                    type: 'aux_hdmi',
                },
                camera: {
                    title: 'Camera front',
                    type: 'vc-camera',
                    mod: 'Camera',
                    index: 1,
                    ignore: true,
                },
            },
            touch_enabled: false,
            vol_min: -400,
            vol_mx: 200,
            doors: [{ title: 'Doors', module: 'Door_1', feedback: 'relay1' }],
        },
        triggers: [],
        created_at: 1529567548,
        id: 'zone-beI-19FMdl',
    },
    {
        name: 'L31 R7 Activity Space',
        description: null,
        tags: null,
        settings: {
            mics: [{ name: 'Soundbar Mic', id: 'mic', basic_mixer: true }],
            inputs: ['Wireless', 'Laptop', 'Web Conference', 'Tele Conference'],
            Laptop: ['laptop_hdmi'],
            Wireless: ['wepresent'],
            'Web Conference': ['webconf'],
            'Tele Conference': ['teleconference'],
            sources: {
                wepresent: {
                    title: 'Clickshare',
                    source: 'hdmi2',
                    type: 'wireless',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['aux'] },
                    ],
                },
                laptop_hdmi: {
                    title: 'Laptop',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['aux'] },
                    ],
                },
                webconf: {
                    title: 'Web Conference',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        { module: 'Mixer_1', method: 'source', args: ['usb'] },
                    ],
                },
                teleconference: {
                    title: 'Tele Conference',
                    source: 'hdmi',
                    type: 'aux_hdmi',
                    custom_tasks: [
                        {
                            module: 'Mixer_1',
                            method: 'source',
                            args: ['bluetooth'],
                        },
                    ],
                },
            },
            touch_enabled: false,
            outputs: {
                Display_1: {
                    title: 'Display',
                    type: 'lcd',
                    pri: 1,
                    basic_mixer: true,
                    mixer_id: 'output',
                    custom_tasks: [
                        {
                            module: 'Display_1',
                            method: 'volume',
                            args: ['100'],
                        },
                        {
                            module: 'Display_1',
                            method: 'speaker_select',
                            args: ['external'],
                        },
                    ],
                    shutdown: {
                        module: 'Display_1',
                        method: 'speaker_select',
                        args: ['internal'],
                    },
                },
            },
            vol_min: 0,
            vol_max: 100,
            lighting_group: 1,
        },
        triggers: [],
        created_at: 1547438444,
        id: 'zone-kG8cn_fkH9',
    },
    {
        name: 'Level 30',
        description: 'level',
        tags: 'level',
        settings: { discovery_info: {}, number: 30 },
        triggers: [],
        created_at: 1495599360,
        id: 'zone-LEHeo501Er',
    },
    {
        name: 'Level 31',
        description: 'level',
        tags: 'level',
        settings: { number: 31, discovery_info: {} },
        triggers: [],
        created_at: 1506945022,
        id: 'zone-QjLXbYUxuC',
    },
    {
        name: 'New Zone',
        description: null,
        tags: null,
        settings: { booking_max_duration: 900 },
        triggers: [],
        created_at: 1550546496,
        id: 'zone-lmhh_hVfz0',
    },
    {
        name: 'Tower 2',
        description: 'building',
        tags: 'building',
        settings: {
            discovery_info: {
                locker_locations: {
                    'zone-QjLXbYUxuC': { West: [100, 200], East: [201, 300] },
                },
                concierge_phone: '+61431485594',
                messaging: 'sys-O8kPamShYv',
                visitor_space: 'towertwovisitors@place.tech',
                locker_structure: {
                    'zone-Mhr8gKmcSS': {
                        City: {
                            personal: [
                                ['31001', '31002', '31003', '31004'],
                                ['31005', '31006', '31007', '31008'],
                                [false, false, '31011', '31012'],
                                ['31013', '31014', '31015', '31016'],
                                ['31017', '31018', '31019', '31020'],
                                ['31021', '31022', '31023', '31024'],
                                ['31025', '31026', '31027', '31028'],
                                ['31029', '31030', '31031', '31032'],
                                ['31033', '31034', '31035', '31036'],
                                [false, false, '31039', '31040'],
                                ['31041', '31042', '31043', '31044'],
                                ['31045', '31046', '31047', '31048'],
                                ['31049', '31050', '31051', '31052'],
                                ['31053', '31054', '31055', '31056'],
                                ['31057', '31058', '31059', '31060'],
                                ['31061', '31062', '31063', '31064'],
                                ['31065', '31066', '31067', '31068'],
                                ['31069', '31070', '31071', '31072'],
                                ['31073', '31074', '31075', '31076'],
                                ['31077', '31078', '31079', '31080'],
                                ['31081', '31082', '31083', '31084'],
                                ['31085', '31086', '31087', '31088'],
                                ['31089', '31090', '31091', '31092'],
                                ['31093', '31094', '31095', '31096'],
                                ['31097', '31098', '31099', '31100'],
                                ['31101', '31102', '31103', '31104'],
                                ['31105', '31106', '31107', '31108'],
                                ['31109', '31110', '31111', '31112'],
                                ['31113', '31114', '31115', '31116'],
                                [false, false, '31119', '31120'],
                                ['31121', '31122', '31123', '31124'],
                                ['31125', '31126', '31127', '31128'],
                            ],
                            pantry: [
                                ['31501', '31502'],
                                ['31503', '31504'],
                                ['31505', '31506'],
                                ['31507', false, false, false, false],
                                ['31508', false, false, false, false],
                                ['31509', false, false, false, false],
                                ['31510', false, false, false, false],
                                ['31511', false, false, false, false],
                                ['31512', false, false, false, false],
                                ['31513', false, false, '31515', false],
                                ['31514', false, false, '31516', false],
                                ['31517', '31518'],
                                ['31519', '31520'],
                                ['31521'],
                                ['31522'],
                            ],
                        },
                        Harbour: {
                            personal: [
                                ['31129', '31130', '31131', '31132'],
                                ['31133', '31134', '31135', '31136'],
                                [false, false, '31139', '31140'],
                                ['31141', '31142', '31143', '31144'],
                                ['31145', '31146', '31147', '31148'],
                                ['31149', '31150', '31151', '31152'],
                                ['31153', '31154', '31155', '31156'],
                                ['31157', '31158', '31159', '31160'],
                                ['31161', '31162', '31163', '31164'],
                                ['31165', '31166', '31167', '31168'],
                                ['31169', '31170', '31171', '31172'],
                                ['31173', '31174', '31175', '31176'],
                                ['31177', '31178', '31179', '31180'],
                                ['31181', '31182', '31183', '31184'],
                                ['31185', '31186', '31187', '31188'],
                                ['31189', '31190', '31191', '31192'],
                                ['31193', '31194', '31195', '31196'],
                                ['31197', '31198', '31199', '31200'],
                                ['31201', '31202', '31203', '31204'],
                                ['31205', '31206', '31207', '31208'],
                                ['31209', '31210', '31211', '31212'],
                                ['31213', '31214', '31215', '31216'],
                                [false, false, '31219', '31220'],
                                ['31221', '31222', '31223', '31224'],
                                ['31225', '31226', '31227', '31228'],
                            ],
                        },
                    },
                },
                terms: [
                    {
                        title: 'Room bookings',
                        details:
                            "Please release booked meeting rooms or spaces when you don't need them anymore, so everyone else knows they're free.",
                    },
                    {
                        title: 'Noise levels',
                        details:
                            'Please be considerate of others and keep the volume down when having conversations or conference calls.',
                    },
                    {
                        title: 'Be considerate',
                        details:
                            "When you've finished using a space, please ensure it is left clean and tidy for the next person.",
                    },
                    {
                        title: 'Centralised waste management',
                        details:
                            "We're part of one of the most sustainable commercial precincts in the world. Please make an effort to place the right waste into the right bins, located in the Pantries.",
                    },
                    {
                        title: 'Lost and found',
                        details:
                            'Any personal or team items left behind in activity rooms or communal spaces will be taken to "Lost and found" at the concierge points.',
                    },
                    {
                        title: 'Invoice Statement',
                        details:
                            'For every confirmed booking, you will receive invoice statement via email for your record.',
                    },
                    {
                        title: 'Billing',
                        details:
                            'Monthly invoice will be issued by 20th of every month for previous month. Invoice is due by end of the month it is issued.',
                    },
                ],
                levels: [
                    {
                        level_id: 'zone-QjLXbYUxuC',
                        level_name: 'Level 31',
                        map_url: 'assets/maps/barangaroo_tower_2/level_31.svg',
                    },
                    {
                        level_id: 'zone-LEHeo501Er',
                        level_name: 'Level 30',
                        map_url: 'assets/maps/barangaroo_tower_2/level_30.svg',
                    },
                ],
                extras: [
                    {
                        extra_id: 'video_conference',
                        extra_name: 'Video Conference',
                    },
                    {
                        extra_id: 'presentation_screen',
                        extra_name: 'Presentation Screen',
                    },
                ],
                catering: {
                    coffee: [
                        {
                            items: [
                                'Cappucino',
                                'Expresso',
                                'Flat White',
                                'Mocha',
                            ],
                        },
                        {
                            items: [
                                'Hot Choc',
                                'Macciato',
                                'Latte',
                                'Long Black',
                            ],
                        },
                        {
                            items: [
                                'Green Tea',
                                'Camomile',
                                'English Tea',
                                'Black Tea',
                            ],
                        },
                        {
                            items: ['Almond', 'Skim', 'Soy', 'Full Cream'],
                            tag: 'milk',
                        },
                        {
                            items: ['Brown', 'Equal', 'Honey', 'White'],
                            tag: 'sugar',
                            amount: 0.5,
                        },
                    ],
                    drinks: [
                        { items: ['Coke', 'Sprite', 'Fanta', 'Lift'] },
                        {
                            items: [
                                'Orange Juice',
                                'Apple Juice',
                                'Breakfast Juice',
                                'Prune Juice',
                            ],
                        },
                        {
                            items: [
                                'Tap Water',
                                'Still Water',
                                'Sparkling Water',
                                'Mineral Water',
                            ],
                        },
                        { items: ['Beer', 'Light Beer', 'Wine', 'Champagne'] },
                    ],
                    food: [
                        {
                            items: [
                                'Bruschetta',
                                'Chicken Burger',
                                'Eggs Blini',
                                'French Toast',
                            ],
                        },
                        {
                            items: [
                                'Spaghetti Bolognese',
                                'Linguine Pesto',
                                'Chorizo Farfalle',
                                'Chicken Tortellini',
                            ],
                        },
                        {
                            items: [
                                'Scallop Ceviche',
                                'Herb Gnicchi',
                                'Duck Salad',
                                'Beef Brisket',
                            ],
                        },
                        {
                            items: [
                                'Rainbow Roll',
                                'Tiger Roll',
                                'Spider Roll',
                                'Ocean Roll',
                            ],
                        },
                    ],
                },
            },
        },
        triggers: [],
        created_at: 1494571187,
        id: 'zone-Kl0HN~nDwc',
    },
];
