<header>
    <h3 mat-dialog-title>{{ title || 'Confirm' }}</h3>
</header>
<mat-dialog-content>
    <div class="body" *ngIf="!loading; else load_state">
        <app-icon class="text-2xl" [icon]="icon"></app-icon>
        <div class="content">
            <p [innerHTML]="content || 'Are you sure?'"></p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!loading">
    <button mat-button class="inverse" mat-dialog-close>Cancel</button>
    <button mat-button name="accept" (click)="accept()">{{ action || 'Ok' }}</button>
</mat-dialog-actions>
<ng-template #load_state>
    <div class="body">
        <div class="info-block">
            <div class="icon">
                <mat-spinner diameter="32"></mat-spinner>
            </div>
            <div class="text">{{ loading }}</div>
        </div>
    </div>
</ng-template>
