<header>
    <h3 mat-dialog-title i18n="@@duplicateHeader">Duplicate Item</h3>
</header>
<mat-dialog-content>
    <div class="body" *ngIf="!loading; else load_state">
        <div class="content">
            <p>How many times would you like to duplicate this item?</p>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    name="times"
                    type="number"
                    [(ngModel)]="times"
                    placeholder="Number of duplications"
                    i18n-placeholder="@@duplcateTimesPlaceholder"
                    required
                />
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!loading">
    <button mat-button class="inverse" mat-dialog-close i18n="@@cancelAction">Cancel</button>
    <button
        mat-button
        name="accept"
        [disabled]="!times || times <= 0"
        (click)="duplicate()"
        i18n="@@duplicateAction"
    >
        Duplicate
    </button>
</mat-dialog-actions>
<ng-template #load_state>
    <div class="body">
        <div class="info" *ngIf="!done">Creating item duplicates...</div>
        <div *ngFor="let itm of temp; let i = index" class="item">
            <div class="name">{{ item.name }} ({{ i + 1 }})</div>
            <div class="status">
                <app-icon *ngIf="status[i] !== 'loading'" [class]="status[i]" [icon]="{ class: status[i] === 'done' ? 'backoffice-check' : 'backoffice-cross' }"></app-icon>
                <mat-spinner *ngIf="status[i] === 'loading'" diameter="24"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>
