<header>
    <h3 mat-dialog-title>Add {{ name }}</h3>
    <button mat-icon-button mat-dialog-close>
        <app-icon [icon]="{ type: 'icon', class: 'backoffice-cross' }"></app-icon>
    </button>
</header>
<mat-dialog-content>
    <div class="body" *ngIf="!loading; else load_state">
        <div class="content">
            <item-search-field [query_fn]="query_fn" [(ngModel)]="item"></item-search-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!loading">
    <button mat-button class="inverse" mat-dialog-close>Cancel</button>
    <button mat-button type="submit" (click)="submit()" [disabled]="!item">Add</button>
</mat-dialog-actions>
<ng-template #load_state>
    <div class="info-block">
        <div class="icon">
            <mat-spinner diameter="32"></mat-spinner>
        </div>
        <div class="text">Processing request...</div>
    </div>
</ng-template>
