<header>
    <h3 mat-dialog-title>Changelog</h3>
    <button mat-icon-button mat-dialog-close>
        <app-icon [icon]="{ type: 'icon', class: 'backoffice-cross' }"></app-icon>
    </button>
</header>
<mat-dialog-content>
    <div
        class="body markdown"
        *ngIf="changelog; else empty_state"
        [innerHTML]="changelog | safe:'html'"
    ></div>
    <ng-template #empty_state>
        <div class="body">
            <div class="info-block">
                <div class="icon">
                    <i class="backoffice-cross"></i>
                </div>
                <div class="text">No changelog</div>
            </div>
        </div>
    </ng-template>
</mat-dialog-content>
